import React, {useEffect, useState} from 'react';
import axios from 'axios';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import {withStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import './App.css';

const styles = {
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
};

function Feature({classes, feature}) {
    return (
        <>
            <CardContent>
                <Typography component="p">
                    {feature.Feature}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" color="primary">Details</Button>
            </CardActions>
        </>
    );
}

const stages = [
    'Under consideration',
    'Planned',
    'In progress',
    'Pull requested',
    'Merged',
];

function App(props) {
    const {classes} = props;
    let [features, setFeatures] = useState([]);
    useEffect(() => {
        axios.get('https://storage.googleapis.com/public.makers.sh/bonobo.json').then((response) => {
            setFeatures(response.data);
        });
    }, []);

    return (
        <div className="App">
            {
                features.map(
                    (release) => {
                        return (
                            <>
                                <Card className={classes.card}>
                                    <Typography variant="h4" component="h1"
                                                style={{padding: 8, backgroundColor: 'white'}}>
                                        <img src="/bonobo.png" alt="Bonobo ETL" style={{height: 42, verticalAlign: 'bottom', marginRight:4}}/>
                                        Bonobo {release.Release}
                                    </Typography>
                                </Card>
                                <div className="Row">
                                    {
                                        stages.map(
                                            (stage, idx) => (
                                                <div className="Col">
                                                    <Card className={classes.card}>
                                                        <CardContent>
                                                            <Typography variant="h5" component="h2">
                                                                {stage}
                                                            </Typography>
                                                        </CardContent>
                                                        {
                                                            release.Features.map(
                                                                (feature, i) => (
                                                                    <Feature key={feature.ID} classes={classes}
                                                                             feature={feature}/>
                                                                )
                                                            )
                                                        }
                                                    </Card>
                                                </div>
                                            )
                                        )
                                    }
                                </div>
                            </>

                        )
                    }
                )

            }

        </div>
    );
}

export default withStyles(styles)(App);